<template>
  <div class="customTable">
    <ag-grid-vue
      :columnDefs="columnsDef"
      :rowData="rowData"
      rowSelection="multiple"
      :pivotPanelShow="true"
      :pagination="true"
      :defaultColDef="defaultColDef"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :columnTypes="columnTypes"
      class="ag-theme-alpine table"
      :enterMovesDown="true"
      :enterMovesDownAfterEdit="true"
      :animateRows="true"
      :rowMultiSelectWithClick="true"
      @cell-value-changed="onCellValueChanged"
      @selection-changed="onSelectionChanged"
      :frameworkComponents="frameworkComponents"
      :tooltipShowDelay="tooltipShowDelay"
      :tooltipMouseTrack="false"
    ></ag-grid-vue>
  </div>
</template>
<script>
//type : text - number -  date
import { moneyFormat, processMoney } from "@/functions";
import { AgGridVue } from "ag-grid-vue";
export default {
  name: "CustomTable",
  data() {
    return {
      selectedRows: [],
      stats: require("@/assets/status.json"),
      gridOptions: {
        // onCellEditingStarted: function() {
        //   console.log("cellEditingStarted");
        // },
        suppressPropertyNamesCheck: true,
        suppressRowClickSelection: true
        // onCellEditingStopped: function() {
        //   console.log("cellEditingStopped");
        // },
      },
      gridApi: null,
      columnApi: null,
      tooltipShowDelay: 50,
      columnTypes: {
        number: {
          filter: "agNumberColumnFilter"
        },
        text: {
          filter: "agTextColumnFilter"
        },
        nonEditableColumn: { editable: false },
        currency: {
          comparator: (a, b) => {
            let first = processMoney(a),
              second = processMoney(b);
            if (second === first) {
              return 0;
            }
            if (second < first) {
              return -1;
            } else if (second > first) {
              return 1;
            } else {
              return 0;
            }
          },
          filterParams: {
            comparator: (a, b) => {
              let first = processMoney(a),
                second = processMoney(b);
              if (second == null) {
                return 0;
              }
              if (second < first) {
                return -1;
              } else if (second > first) {
                return 1;
              } else {
                return 0;
              }
            }
          },
          valueFormatter(params) {
            return moneyFormat(params.value);
          }
        },
        date: {
          valueFormatter(params) {
            let date = new Date(params.value),
              month = date.getMonth() + 1,
              monthStr = month > 9 ? month : "0" + month,
              day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
            return monthStr + "/" + day + "/" + date.getFullYear();
          },
          comparator(d1, d2) {
            if (d1 === d2) {
              return 0;
            }
            var Date1 = new Date(d1);
            var Date2 = new Date(d2);
            if (Date1 < Date2) {
              return -1;
            } else if (Date1 > Date2) {
              return 1;
            } else {
              return 0;
            }
          },
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              if (cellValue == null) {
                return 0;
              }
              var cellDate = new Date(cellValue);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            }
          }
        },
        status: {
          tooltipValueGetter: params => {
            // if (!this.$store.getters.luaderStatusFetched) {
            //   this.$store.dispatch("fetchStatusNames");
            // }
            return `
              ${this.getStatusDescription(this.stats, params.value)}
            `;
          }
        },
        gender: {
          cellEditor: "",
          cellEditorParams: {
            cellRenderer: "genderCellRenderer",
            values: ["Male", "Female"]
          },
          cellRenderer: "genderCellRenderer"
        },
        checkbox: {
          cellRenderer(params) {
            if (params.value !== "1" && params.value !== "0") {
              params.setValue(
                params.value === true || params.value === "1" ? "1" : "0"
              );
            } else {
              var input = document.createElement("input");

              input.type = "checkbox";
              input.value =
                params.value === true || params.value === "1" ? "1" : "0";
              input.checked =
                params.value === true || params.value === "1" ? true : false;

              input.onclick = function() {
                params.setValue(this.checked === true ? "1" : "0");
              };

              return input;
            }
          }
        }
      },
      frameworkComponents: {},
      defaultColDef: {
        editable: false,
        type: "text",
        filter: true,
        floatingFilter: true,
        resizable: true,
        sortable: true,
        flex: 1
      }
    };
  },
  methods: {
    getStatusDescription(stats, param) {
      let data = stats.filter(
        r => r.name.toLowerCase().trim() === param.toLowerCase()
      );
      return data.length > 0 && data[0].description
        ? data[0].description
        : param;
    },
    onCellValueChanged() {
      // console.log(params);
    },
    onRemoveSelected() {
      var selectedData = this.gridApi.getSelectedRows();
      this.gridApi.applyTransaction({ remove: selectedData });
      this.$emit("DeleteButtonClicked", selectedData);
    },
    onGridReady() {
      this.$emit("GridReady");
      this.gridApi = this.gridOptions ? this.gridOptions.api : null;
      this.columnApi = this.gridOptions ? this.gridOptions.columnApi : null;
    },
    onSelectionChanged() {
      this.$emit("selected", this.gridApi.getSelectedRows());
      this.selectedRows = this.gridApi.getSelectedRows();
    }
  },
  components: {
    AgGridVue
  },
  computed: {
    rowData() {
      if (this.data && this.data.length > 0) {
        return this.data;
      } else {
        return [];
      }
    },
    columnsDef() {
      let cols = this.columns;
      cols.forEach((col, index) => {
        if (index === 0) {
          cols[index].checkboxSelection = true;
          cols[index].headerCheckboxSelection = true;
          cols[index].headerCheckboxSelectionFilteredOnly = true;
        }
      });
      return cols;
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  }
};
// function GenderCellRenderer() {}
// GenderCellRenderer.prototype.init = function(params) {
//   this.eGui = document.createElement("span");
//   if (
//     params.value !== "" ||
//     params.value !== undefined ||
//     params.value !== null
//   ) {
//     let gender = params.value == true || params.value == 1 ? "male" : "female";
//     var div =
//       '<img border="0" width="15" height="10" src="https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/images/' +
//       gender.toLowerCase() +
//       '.png">';
//     this.eGui.innerHTML = div + " " + gender;
//   }
// };
// GenderCellRenderer.prototype.getGui = function() {
//   return this.eGui;
// };
</script>
<style scoped></style>
<style lang="scss">
@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
.table {
  width: 100%;
  height: calc(80vh - 25px);
}
.ag-tooltip {
  max-width: 400px;
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: #0277bd !important;
  color: white !important;
}
</style>
